<template>
  <v-dialog
    v-model="showDialog"
    max-width="500"
    persistent
    transition="dialog-transition"
    class="dia-log-select"
  >
    <v-container class="dialog-customer-container">
      <div class="px-3 pt-4 d-flex">
        <!--header-dialog-->
        <span class="d-flex text-left align-center header-title">{{ $t(title) }}</span>
        <v-icon class="close-dialog mt-1" @click="closeDialog">mdi-close</v-icon>
      </div>
      <div class="d-flex flex-column mt-3 px-3 selected-source-task clear-display">
        <v-text-field
          v-model="searchKeyword"
          :placeholder="$t('place_holders.search-code-name-phone')"
          class="text--body-5 mt-2"
          clearable
          dense
          height="40px"
          outlined
          @change="searchKeyword = trimSpace(searchKeyword)"
          @keyup.enter="search()"
        >
          <template v-slot:append>
            <v-img
              :src="require('@/assets/icons/search.svg')"
              class="hover--pointer"
            />
          </template>
        </v-text-field>
        <div class="d-flex align-center count-source">
          <span>Người phụ trách</span>
        </div>
        <div class="body-dialog-select-source-task align-center">
          <div class="col-12 pa-0">
            <div
              v-if="totalRecord === 0"
            >
              <div class="no-data"> {{ $t("no-data") }} </div>
            </div>
            <v-radio-group v-model="objectEmployee">
              <v-radio
                v-for="(item,index) in listEmployee"
                :key="index"
                :value="item.id"
                class="d-flex justify-center align-center height-50 mb-3"
                style="box-shadow: 0px 1px 0px #E7ECF1;"
              >
                <template v-slot:label>
                  <div class="d-flex flex-row justify-center align-center" />
                  <v-list-item>
                    <v-list-item-content>
                      <div class="text--body-6-14 text-ellipsis">
                        <span>{{ getTextTruncateView(item.name,15) }} {{ getTextTruncateView(getTextCode(item),15) }}</span>
                      </div>
                      <v-list-item-subtitle>{{ item.phone }}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{ getTextTruncateView(item.email,15) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </div>
      </div>
      <v-card-actions class="mt-3 justify-center btn-action">
        <v-btn
          class="w-150 py-5 text--button"
          color="primary"
          outlined
          @click="closeDialog"
        >
          Hủy
        </v-btn>
        <v-btn
          class="w-150 py-5 text--button"
          color="primary"
          @click="selectEmployee"
        >
          Chọn
        </v-btn>
      </v-card-actions>
    </v-container>
    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />
  </v-dialog>
</template>

<script>
import constants from "@/constants";
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import { UserService } from "@/services/userService";
import { getTextTruncate } from "@/helpers/stringUtils.js"
import { StorageService } from "@/services/storageService";

export default {
  name: 'DialogCustomer',
  components: {
    DialogLoading
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      lstOparator: [],
      listEmployee: [],
      objectEmployee: null,
      searchKeyword: '',
      showLoading: false,
      totalRecord: null
    }
  },
  methods: {
    getDataRadio(id) {
      this.objectEmployee = id;
    },
    getTextCode(item) {
      if (item.code === "") {
        return ""
      }
      return `(${item.code})`
    },
    async searchEmployeePostoffice() {
      const pars = {
        objSearch: this.searchKeyword,
        roles: [constants.roleSale],
        poId: "",
        currentPage: 1,
        limit: 1000
      }
      const { status, data } = await UserService.searchEmployeePostoffice(pars)
      if (status === constants.statusCode.ok && data) {
        this.listEmployee = data.data
      }
    },
    async search() {
      const pars = {
        objSearch: this.searchKeyword,
        roles: [constants.roleSale],
        poId: StorageService.getPostOfficeId(),
      }
      this.showLoading = true
      const { status, data } = await UserService.searchEmployeePostoffice(pars)
      if (status === constants.statusCode.ok && data) {
        setTimeout(() => {
          this.showLoading = false
          this.listEmployee = data.data
          this.totalRecord = data.totalRecord
        }, 1500);
      }
    },
    closeDialog() {
      this.searchKeyword = ""
      this.totalRecord = null
      this.$emit('close-dia-log')
    },
    selectEmployee() {
      this.$emit('select-employee', this.objectEmployee, this.listEmployee)
      this.$emit('close-dia-log')
    },
    trimSpace(val) {
      return val ? val.trim() : null
    },
    getTextTruncateView(val, size) {
      return getTextTruncate(val, size);
    },
  },
}
</script>
<style src="@/styles/dialogCustomer.scss" lang="scss">
</style>
